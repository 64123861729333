// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import Platform_Feat_slider from "../../components/common/platform-feat-slider"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//import platform feature icons

import precision_pink from "../../images/narrow-width-fabric/icons/precision_pink.svg"
import compression_pink from "../../images/narrow-width-fabric/icons/compression_pink.svg"
import coloration_pink from "../../images/narrow-width-fabric/icons/coloration_pink.svg"
import stretchable_pink from "../../images/narrow-width-fabric/icons/stretchable_pink.svg"

const NarrowWidthFabric = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),

    hero: convertToBgImage(getImage(data.hero)),

    //application images
    configure_img: convertToBgImage(getImage(data.app1)),
    workwear: convertToBgImage(getImage(data.workwear)),
    performance: convertToBgImage(getImage(data.performance)),
    consumer_tech: convertToBgImage(getImage(data.consumer_tech)),
    tech_spec_img: convertToBgImage(getImage(data.app5)),
    consumer_health: convertToBgImage(getImage(data.consumer_health)),

    //platform capability images
    configurations: convertToBgImage(getImage(data.configurations)),
    tech_specifications: convertToBgImage(getImage(data.tech_specifications)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_Knit: convertToBgImage(getImage(data.engineered_Knit)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const platform_feat_slider_array = [
    {
      title: "Stretchability",
      desc: "Performance narrow width elastics with varied elasticity.",
      icon: stretchable_pink,
    },

    {
      title: "Coloration ",
      desc: "Dyeing and printing techniques used to achieve color palette of choice.",
      icon: coloration_pink,
    },

    {
      title: "Compression",
      desc: "Enabling gradient compression for stronger fit.",
      icon: compression_pink,
    },

    {
      title: "Precision knit ",
      desc: " High-accuracy fabric construction.",
      icon: precision_pink,
    },
  ]

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_Knit,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const [active, setActive] = useState(0)

  //   const howItWorks = [
  //     {
  //       title: "LED",
  //       content:
  //         " Silicone encapsulated conductive pathways for on-garment power flow.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.led,
  //     },
  //     {
  //       title: "Pathways",
  //       content:
  //         " Silicone encapsulated conductive pathways for on-garment power flow.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.dusk_texure,
  //     },
  //     {
  //       title: "Module",
  //       content:
  //         " Silicone encapsulated conductive pathways for on-garment power flow.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.led,
  //     },
  //     {
  //       title: "Integration",
  //       content:
  //         " Silicone encapsulated conductive pathways for on-garment power flow.",
  //       image: "../../images/brand/symbol-mint.png",
  //       cover: images.led,
  //     },
  //   ]

  const platform = [
    {
      title: "Technology Specifications",
      sub_title: "Technology Integration",
      content:
        "Technology enables for active technology platforms.  Truly stretchable electrics act as an interface for data transfer ",
      //   content3:
      //     "",
      image: "../../images/brand/symbol-mint.png",
      cover: images.tech_specifications,
    },
    {
      title: "Configurations",
      content: "+ Crochet and Jacquard machinery ",
      image: "../../images/brand/symbol-mint.png",
      cover: images.configurations,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  return (
    <Layout>
      <Seo title="Narrow Width Fabric" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Narrow Width Fabrics
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Precision knit elastics for a multitude of consumer
                    applications
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-salmon mb-4 uppercase text-center ">
                    STRETCH. FIT. SMART.
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Enabling Elastic Technologies
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    In partnership with the world’s largest producer of
                    narrow-width fabrics, we offer a collection of elasticated
                    fabrics and elastomeric yarns. Engineered to achieve
                    required stretch and durability, our portfolio of
                    narrow-width fabrics supports complex design parameters and
                    construction needs.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <div></div>

                {/* <div className="grid grid-cols-4 gap-4 mt-8 text-dusk"> */}

                {/* </div> */}
                {/* <div className="border border-salmon p-5 pb-10 group ">
                      <div>
                        <img
                          src={lightweight_pink}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug ">
                        Lightweight
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Foam base designed to be lighter than traditionally
                        housed plastic.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group ">
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug ">
                        Compact
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Encapsulation of all devices into a single unit, reduces
                        the overall footprint.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group ">
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug ">
                        Formulation
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Our patented technology enables formulation under low
                        temperature and pressure. Making this technology ideal
                        for encapsulating electronic devices
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group ">
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug ">
                        Inexpensive
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Single process technique to house all components.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group ">
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug ">
                        Flexible
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Solid structures with seamless durometer, created for
                        the required force gradient.
                      </div>
                    </div> */}
              </div>
            </div>
            <div className="mt-8">
              <Platform_Feat_slider
                platform_feat_slider_array={platform_feat_slider_array}
              />
            </div>
            {/* <div className="mt-24">
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl font-light text-dusk mb-4">
                    How is works
                  </div>
                  <div className="text-2xl font-light text-dusk max-w-4xl leading-snug">
                    The most complete next-to-skin lighting system that is
                    machine washable, power-efficient, and keeps you safe.
                  </div>
                </div>
              </Fade>
              <div className="mt-14">
                <div className="flex items-center justify-center gap-32">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div
                            className={
                              active == index
                                ? "font-firs-semibold cursor-pointer text-dusk text-xl pb-2 border-b-2 px-5 border-dusk duration-200"
                                : "font-firs-semibold cursor-pointer text-dusk text-xl pb-2 px-5 duration-200"
                            }
                            onClick={() => setActive(index)}
                          >
                            {item.title}
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-8">
                  <Fade bottom>
                    <div className="flex">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className="w-2/5 bg-red-400"
                      ></BackgroundImage>
                      <div className="w-3/5 bg-dusk p-14">
                        <StaticImage
                          src="../../images/brand/symbol-mint.png"
                          width={40}
                          alt="Softmatter"
                        />
                        <div className="text-2xl font-light mt-8 text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white max-w-lg">
                          {howItWorks[active].content}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section>
        <div className="py-52 bg-mint">
          <div className="container mx-auto flex items-center">
            <Fade bottom cascade>
              <div className="w-1/2">
                <div className="text-4xl font-light mb-4 text-dusk ">
                  Patented. Awarded. Different.
                </div>
                <div className="text-2xl font-light max-w-5xl leading-snug text-white">
                  Our groundbreaking textile-integrated illumination technology
                  that is revolutionizing the future of apparel, setting new
                  standards for safety, visibility, and product differentiation.
                  The platform offers more than 450 feet of visibility in low
                  light conditions with over 8 hours of continuous illumination.
                </div>
                <div className="text-2xl font-light max-w-5xl leading-snug mt-4 text-white">
                  More recent developments have helped us crack the code on RGB
                  color optionality and gradients, built to allow brands to
                  better engage with their customers aesthetically.
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className=" absolute right-0">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/visibility/lumio-stretch.jpg"
                  alt="Softmatter"
                  width={700}
                />
              </div>
            </Fade>
          </div>
        </div>
      </section> */}
      <section>
        <div className="bg-dusk overflow-hidden">
          <div className=" pt-24">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  OUR NARROW WIDTH
                </div>
                <div className="text-center sm:text-7xl text-5xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Narrow width fabrics are used across the board in our work at
                  Softmatter from backing for conductive pathways to
                  form-fitting garments
                </div>
              </div>
            </Fade>
            <div className="mt-32">
              <div className="flex lg:flex-row flex-col">
                <Fade right>
                  <div className="lg:w-1/2 w-full lg:hidden block ">
                    <BackgroundImage
                      {...images.consumer_health}
                      className="py-60 "
                    ></BackgroundImage>
                  </div>
                </Fade>
              </div>
              <div className=" flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10 ">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Health
                      </div>
                      <div className="text-base lg:max-w-xl max-w-2xl">
                        A backing substrate for fabric-based data transfer; used
                        to build straps for next to skin wearables and
                        biopotential monitors, and much more.
                      </div>
                      <div className="text-base lg:max-w-3xl max-w-2xl mt-4">
                        <div className="text-base  mt-2">
                          + Harness for masks
                        </div>
                        <div className="text-base  mt-2 ">
                          + Silicone extruded elastics for high-grip
                          applications
                        </div>
                        <div className="text-base  mt-2 ">
                          + Stretchable conductive pathways
                        </div>
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.consumer_health}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.consumer_tech}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center sm:text-left text-center lg:py-0 py-10">
                  <Fade>
                    <div className="text-white pr-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Technology
                      </div>
                      <div className="text-base lg:max-w-xl max-w-2xl">
                        Watch straps and armbands can be programmatically
                        actuated and linked, allowing for the creation of
                        intelligent wearables that respond to external stimuli,
                        or user-designed patterns.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.performance}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center sm:text-left text-center lg:py-0 py-10">
                  <Fade>
                    <div className="text-white pr-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Outdoor and Workwear
                      </div>
                      <div className="text-base lg:max-w-xl max-w-2xl">
                        Integration with activewear to achieve compression,
                        stretch, and fit-to-form, as well as allowing for varied
                        modulus across soft goods. Whilst in workwear we are
                        developing functional apparel and soft goods with
                        tailored fit, breathability, and great hand-feel –
                        optimized for both comfort and aesthetics.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:block hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.performance}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Capabilities
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                Elasticated fabrics and elastomeric yarns engineered to achieve
                stretch and durability, can support complex design parameters
                and construction needs.
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 gap-5">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-lg pb-2 border-b-2 px-5 border-dusk duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-lg pb-2 px-5 duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <div className="lg:w-2/5 w-full">
                      <BackgroundImage
                        {...platform[active].cover}
                        className=" w-full bg-red-400 py-44"
                      ></BackgroundImage>
                    </div>
                    <div className="lg:w-3/5 w-full bg-dusk p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[active].sub_title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content2}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-44">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full ">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Get Started
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-salmon  hover:bg-salmon/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query NarrowWidthFabricImages {
    hero: file(relativePath: { eq: "narrow-width-fabric/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app1: file(
      relativePath: { eq: "injection-molding/Applications/Configurations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workwear: file(relativePath: { eq: "narrow-width-fabric/workwear.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    performance: file(
      relativePath: { eq: "narrow-width-fabric/performance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    consumer_tech: file(
      relativePath: { eq: "narrow-width-fabric/consumer_tech.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app5: file(
      relativePath: { eq: "injection-molding/Applications/Tech_Specs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    consumer_health: file(
      relativePath: { eq: "narrow-width-fabric/consumer_health.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    configurations: file(
      relativePath: { eq: "narrow-width-fabric/configurations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    tech_specifications: file(
      relativePath: { eq: "narrow-width-fabric/tech_specifications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    engineered_Knit: file(
      relativePath: {
        eq: "narrow-width-fabric/textile_engineering/engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default NarrowWidthFabric
